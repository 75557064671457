import React, { useContext, useEffect, useRef, useState } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PageTransition from "../../components/PageTransition";

import { Context } from "../../Store";
import PageLoader from "../../components/PageLoader";
import PlotCard from "../../components/PlotCard";
import PlotBlockPlan from "../../components/PlotBlockPlan";
import Siteplan from "../../components/Siteplan";

import { getStatusID } from "../../utilities";

import { ReactComponent as ResetIcon } from "../../icons/compress-arrows-alt-solid.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar } from "swiper";
SwiperCore.use([Scrollbar]);

const MainWrapper = {
  width: "100%",
  height: "100%",
};

export default function App() {
  const [appState] = useContext(Context);
  const [siteplans, setSiteplans] = useState(false);
  const [plotList, setPlotList] = useState(false);
  const [activeSiteplan, setActiveSiteplan] = useState(false);
  const [availableStatus, setAvailableStatus] = useState(false);
  const [showBlockPlan, setShowBlockPlan] = useState(null);

  //Get available status
  useEffect(() => {
    setAvailableStatus(getStatusID(appState.plotStatuses, "Available"));
  }, [appState.plotStatuses]);

  //check if a plot is on a plan
  //list plans with plots only
  useEffect(() => {
    // let onSitePlan = false
    let activeSiteplans = [];

    if (
      appState.currentTypeData &&
      appState.currentTypeData.plots &&
      appState.siteplanData
    ) {
      //if we have type data update with siteplans data
      let updatedPlots = appState.currentTypeData.plots.map((plot) => {
        //loop each plot

        //check the plan for plots phase
        let phase = plot.phase;
        let plotNumber = plot.plotNumber;
        let onSitePlan = false;

        if (phase) {
          //check that plan
          let currentPlan = appState.siteplanData.filter((plan) => {
            if (plan.name === phase) {
              return true;
            }
            return null;
          })[0];

          if (currentPlan) {
            currentPlan["plotsToShow"] = [];
          }

          //loop that plan
          if (currentPlan) {
            currentPlan.frameData.map((frameDataItem) => {
              let itemName = Object.keys(frameDataItem)[0];
              let itemPlotNumber = parseInt(itemName.replace("plot_", ""));

              if (
                itemName.includes("plot_") &&
                parseInt(itemPlotNumber) === parseInt(plotNumber)
              ) {
                // add this current plan to the active plans
                if (!activeSiteplans.find((x) => x.name === phase)) {
                  activeSiteplans.push(currentPlan);
                }

                //mark plot as on a plan
                onSitePlan = true;
              }
              return null;
            });

            setActiveSiteplan(currentPlan.name);
          }
        } else {
          // Check the single plan

          // if no phase try and match to the first
          let currentPlan = appState.siteplanData[0];

          if (currentPlan) {
            currentPlan["plotsToShow"] = [];
          }

          //loop that plan
          if (currentPlan) {
            currentPlan.frameData.map((frameDataItem) => {
              let itemName = Object.keys(frameDataItem)[0];
              let itemPlotNumber = parseInt(itemName.replace("plot_", ""));
              if (
                itemName.includes("plot_") &&
                parseInt(itemPlotNumber) === parseInt(plotNumber)
              ) {
                // add this current plan to the active plans
                if (activeSiteplans.length < 1) {
                  activeSiteplans.push(currentPlan);
                }

                //mark plot as on a plan
                onSitePlan = true;
              }
              return null;
            });
            setActiveSiteplan(currentPlan.name);
          }
        }

        // Check if plot is an apartment by checking if it has floor
        //get the plot containers
        let sitesContainer = appState.plotContainers[0];
        let sites;
        if (sitesContainer.plotContainerType.name !== "Site") {
          sites = appState.plotContainers[0].plotContainers;
        } else {
          sites = appState.plotContainers;
        }
        let isApartment = false;
        let position;

        sites.forEach((site) => {
          let blocks = site.plotContainers;
          blocks.forEach((block) => {
            let floors = block.plotContainers;
            //console.log(floors)
            floors.forEach((floor) => {
              if (floor.plotIds.includes(plot.id)) {
                isApartment = true;
                let interactivePlotRegions = floor.interactiveRegions;
                interactivePlotRegions.forEach((potentialPlot) => {
                  if (potentialPlot.targetId === plot.id) {
                    position = {
                      x: potentialPlot.startX,
                      y: potentialPlot.startY,
                      width: potentialPlot.width,
                      height: potentialPlot.height,
                    };
                  }
                });
              }
            });
          });
        });
        return { ...plot, onSitePlan, isApartment, position };
      });

      //update plot list state
      setPlotList(updatedPlots);

      //update phase list state
      setSiteplans(activeSiteplans);
      // setSiteplans(appState.siteplanData) //for testing
    }
  }, [appState.currentTypeData, appState.siteplanData]);

  function showPlotMarker(plotNumber, phase, event) {
    event.stopPropagation();

    let i = 0;
    if (phase) {
      //get plan key from Phase name
      i = siteplans.findIndex((plan) => plan.name === phase);
    }

    const zeroPad = (num, places) => String(num).padStart(places, "0");
    let plotPad = zeroPad(plotNumber, 3); // "00#"

    if (i > -1) {
      let plotData = siteplans[i].frameData.filter((item, index) => {
        let itemName = Object.keys(item)[0];
        let dataPlotNumber = parseInt(itemName.replace("plot_", ""));
        if (
          itemName.includes("plot_") &&
          parseInt(dataPlotNumber) === parseInt(plotNumber)
        ) {
          return true;
        } else {
          return false;
        }
      })[0][`plot_${plotPad}`];
      //add the plot number to the object

      if (plotData && plotNumber) {
        plotData["plotNumber"] = plotNumber;
      }

      //get the plot index base on plot number
      let plotIndex = siteplans[i].plotsToShow.findIndex(
        (plot) => plot.plotNumber === plotNumber
      );

      //make a copy
      let newSiteplans = [...siteplans];

      if (plotIndex > -1) {
        //remove from list
        newSiteplans[i].plotsToShow.splice(plotIndex, 1);
      } else {
        // add to list
        newSiteplans[i].plotsToShow.push(plotData);
      }

      setSiteplans(newSiteplans);
      if (phase) {
        setActiveSiteplan(phase);
      }
      zoomToPlot(plotNumber, phase);
    }
  }

  function zoomToPlot(plotNumber, phase) {
    let activePlot = isPlotActive(plotNumber, phase);

    const { zoomToElement } = transformComponentRef.current;

    if (activePlot) {
      setTimeout(function () {
        zoomToElement(`pin-${plotNumber}`, 2);
      }, 100);
    }
  }

  function isPlotActive(plotNumber, phase) {
    let i = 0;
    if (phase) {
      i = siteplans.findIndex((plan) => plan.name === phase);
    }

    if (i > -1) {
      //get the plot index base on plot number
      let plotIndex = siteplans[i].plotsToShow.findIndex(
        (plot) => plot.plotNumber === plotNumber
      );

      if (plotIndex > -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  // function switchSiteplan(phase, event) {
  //   event.stopPropagation()
  //   setActiveSiteplan(phase)
  // }

  const transformComponentRef = useRef(null);
  const [scale, setScale] = useState(0.4);

  // const updateScale = (e) => {
  //   const targetScale = parseFloat(e.target.value)
  //   const factor = Math.log(targetScale / scale)
  //   const { zoomIn, zoomOut } = transformComponentRef.current

  //   if (targetScale > scale) {
  //     zoomIn(factor, 0)
  //   } else {
  //     zoomOut(-factor, 0)
  //   }

  //   setScale(targetScale)
  // }

  // function resetCenter() {
  //   const { centerView } = transformComponentRef.current
  //   centerView(scale)
  // }

  return (
    <PageTransition>
      <div style={MainWrapper}>
        <TransformWrapper
          ref={transformComponentRef}
          onZoomStop={(e) => {
            setScale(e.state.scale);
          }}
          initialScale={scale}
          minScale={0.3}
          maxScale={1}
          doubleClick={{
            disabled: true,
          }}
          limitToBounds={true}
          zoomAnimation={{ disabled: true }}
          centerOnInit
          wheel={{
            wheelEnabled: false,
          }}
        >
          {({ centerView, zoomToElement, ...rest }) => {
            return (
              <>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Siteplan
                    siteplans={appState.siteplanData}
                    activeSiteplan={activeSiteplan}
                  />
                </TransformComponent>
                <div className="tools">
                  <button onClick={() => centerView(scale, 300, "easeOut")}>
                    <div className="svg-icon-inline baseline no-padding">
                      <ResetIcon />
                    </div>
                  </button>
                </div>
              </>
            );
          }}
        </TransformWrapper>

        {showBlockPlan !== null && (
          <PlotBlockPlan
            plot={showBlockPlan}
            close={() => setShowBlockPlan(null)}
          />
        )}

        <Swiper
          spaceBetween={50}
          slidesPerView={4}
          centeredSlides={true}
          centerInsufficientSlides={true}
          centeredSlidesBounds={true}
          observer
          observeParents
          scrollbar={{ draggable: false, el: ".scrollbar-menu__scrollbar" }}
          className="homes-swiper"
          preventClicks={false}
          preventClicksPropagation={false}
        >
          {plotList.length >= 1 ? (
            plotList.map((plot) => {
              return plot.plotStatusId === availableStatus ? (
                <SwiperSlide key={plot.plotNumber}>
                  <div className="swiper__slide">
                    <PlotCard
                      plot={plot}
                      showPlotMarker={showPlotMarker}
                      isPlotActive={isPlotActive}
                      isApartment={plot.isApartment}
                      showBlockPlan={() => setShowBlockPlan(plot)}
                      isShowBlockPlanActive={
                        showBlockPlan !== null
                          ? showBlockPlan.id === plot.id
                          : false
                      }
                    />
                  </div>
                </SwiperSlide>
              ) : null;
            })
          ) : (
            <PageLoader />
          )}
        </Swiper>
      </div>
    </PageTransition>
  );
}
