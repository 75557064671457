import React from "react"
import Page from "./Page"
import Footer from "../components/Footer"
import PulseButton from "../components/PulseButton"
import PageTransition from "../components/PageTransition"

function Home() {
  return (
    <Page title="Home">
      <div className="main">
        <div className="content">
          <PageTransition>
            <PulseButton />
            <h2 className="page-title">Find your new home</h2>
          </PageTransition>
        </div>
      </div>
      <Footer type="home" />
    </Page>
  )
}

export default Home
