import React, { useEffect, useContext, useMemo } from "react"
import { Context } from "./Store"
import "./styles/index.scss"
import Loader from "./components/Loader"

import getThinkHubData from "./useThinkHubData"
import getSiteplanData from "./useSiteplanData"
import plotsToTypes from "./plotsToTypes"
import { setUpFilters, runFilter } from "./filters"

import { BrowserRouter as Router } from "react-router-dom"

import AppSettings from "./pages/AppSettings"
import AppRoutes from "./pages/AppRoutes"
import Theme from "./components/Theme"
import IdleTimerContainer from "./components/IdleTimerContainer"


const App = () => {
  const [appState, appDispatch] = useContext(Context)

  //block contextmenu
  document.addEventListener("contextmenu", (event) => event.preventDefault())

  //set dev id and key to local store on change
  useEffect(() => {
    if(appState.devId && appState.devId) {
      localStorage.setItem("devId", appState.devId)
      localStorage.setItem("devKey", appState.devKey)
      localStorage.removeItem("siteplanFrames")
    }
  }, [appState.devId, appState.devKey])

  //save siteplan frame id to local store
  useEffect(() => {
    if(appState.siteplanFrames) {
      localStorage.setItem("siteplanFrames", JSON.stringify(appState.siteplanFrames))
    }
  }, [appState.siteplanFrames])

  //Get all the ThinkHUB data

  getThinkHubData()
  getSiteplanData()

  //Transform plots to types
  useEffect(() => {
    if (appState.allLoaded) {
      let types = plotsToTypes(appState.plotsData, appState.plotStatuses)
      appDispatch({ type: `SET_TYPES_DATA`, payload: types })
    }
  }, [appState.allLoaded])

  //Add block and floor data to plots
  useEffect(() => {
    if (appState.plotContainers) {
      //loop each plot
      const plots = appState.plotsData.map((currentPlot) => {
        //get the plot containers
        let data = appState.plotContainers[0]

        let sites = ""
        if (data.plotContainerType.name !== "Site") {
          sites = appState.plotContainers[0].plotContainers
        } else {
          sites = appState.plotContainers
        }

        let matchedFloor = false

        sites.forEach(function (site) {
          let blocks = site.plotContainers

          blocks.forEach(function (block) {
            let floors = block.plotContainers

            floors.forEach(function (floor) {
              if (floor.plotIds.includes(currentPlot.id)) {
                let plots = floor.interactiveRegions
                plots.forEach(function (plot) {
                  if (plot.targetId === currentPlot.id) {
                    // Found a matching floor
                    matchedFloor = {
                      blockName: block.name,
                      floorName: floor.name,
                      imgURL: floor.interactiveAssetURI,
                      x: plot.startX,
                      y: plot.startY
                    }
                  }
                })
              }
            })
          })
        })
        //return the matched floor
        return { ...currentPlot, floorData: matchedFloor }
      })
      appDispatch({
        type: `SET_PLOTS_DATA`,
        payload: plots
      })
    }
  }, [appState.plotContainers])

  //Set up the filters
  useEffect(() => {
    if (appState.allLoaded) {
      setUpFilters(appState, appDispatch)
    }
  }, [appState.allLoaded])

  //Filter homes on filter change
  useEffect(() => {
    if (appState.allLoaded) {
      runFilter(appState, appDispatch)
    }
  }, [appState.availableHomes, appState.filters])

  //Get siteplan info from menu items
  useEffect(() => {
    if (appState.allLoaded) {
      let siteplans = appState.plotContainers.map((item) => {
        return {
          slug: item.id,
          url: `https://assets.think-online.co.uk/dev/${appState.devId}/${item.id}`,
          title: item.name
        }
      });
      appDispatch({ type: `SET_SITEPLANS`, payload: siteplans })
    }
  }, [appState.allLoaded])

  //cache images function
  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        window[src] = img
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises).then((values) => {
      appDispatch({ type: `SET_IMAGES_PRELOADED` })
    })
  }

  //preload all images
  useEffect(() => {
    const srcArray = []
    //build src array to preload
    if (appState.typesData) {
      appState.typesData.map((type) => {
        type.assets.map((assets) => {
          srcArray.push(assets.fileUrl)
          return null
        })
        return null
      })
      cacheImages(srcArray)
    }
  }, [appState.typesData])

  if( appState.devId && appState.devKey && appState.siteplanFrames ) {
    return (
      <Router>
        {appState.allLoaded && appState.imagesPreloaded ? (
          <>
            <Theme />
            <AppRoutes />
          </>
        ) : (
          <Loader />
        )}
        <IdleTimerContainer />
      </Router>
    )
  } else {
    return (
      <AppSettings />
      )
  }

  
}

export default App
