//utilities
import { stringToSlug, fromPrice, toPrice, getStatusID } from "./utilities"

//Set up filters
//set available homes and set up filters

export function setUpFilters(appState, appDispatch) {
  // let prices = []
  let bedrooms = []
  // let houseTypes = []
  // let floors = []

  // let allCustomFilters = []

  let filtersList = []

  // loop all the plots
  if (appState.plotsData && appState.plotStatuses) {
    let homes = appState.plotsData.filter((el) => {
      //filter out hidden
      if (el.plotStatusId !== getStatusID(appState.plotStatuses, "Hidden")) {
        //only show available homes.
        if (
          el.plotStatusId === getStatusID(appState.plotStatuses, "Available")
        ) {
          //get a list of all prices
          // prices.push(el.price)

          //get list of all bedrooms
          bedrooms.push(el.plotType.numberOfBeds)

          //get a list of all types
          // houseTypes.push(el.plotType.name)

          //get a list of all floors
          // if (el.floorData) {
          //   floors.push(el.floorData.floorName)
          // }

          //get all custom filters
          // if (el.customFields) {
          //   el.customFields.filter((cf) => {
          //     if (cf && cf.dataFilterType !== null) {
          //       allCustomFilters.push(cf)
          //       return cf
          //     } else {
          //       return null
          //     }
          //   })
          // }

          //return the plot to the home variable
          return el
        }
      }
      return null
    })

    //get price info
    // let maxPrice = toPrice(prices)
    // let minPrice = fromPrice(prices)

    //get unique bedrooms
    let uniqueBedrooms = [...new Set(bedrooms)] //get unique
    let orderedBedrooms = uniqueBedrooms.sort((a, b) => a - b)

    //get unique floors
    // let uniqueFloors = [...new Set(floors)]
    // let orderedFloors = uniqueFloors.sort((a, b) => a - b)

    //house types
    // let uniqueHouseTypes = [...new Set(houseTypes)]
    // let orderedHouseTypes = uniqueHouseTypes.sort((a, b) => a - b)

    // add to filters data
    // price
    // if (!isFinite(minPrice) && !isFinite(maxPrice)) {
    //   // don't add the filter
    // } else if (minPrice === maxPrice) {
    //   // don't add the filter
    // } else {
    //   filtersList.push({
    //     id: 1,
    //     name: "price",
    //     displayName: "Price Range",
    //     type: "range",
    //     values: [minPrice, maxPrice],
    //     selectedValues: [minPrice, maxPrice]
    //   })

    //   let newRangeLabels = [...appState.rangeLabels] // copying the old
    //   newRangeLabels[1] = [minPrice, maxPrice]

    //   // appDispatch({
    //   //   type: "setRangeLabels",
    //   //   data: newRangeLabels
    //   // })
    // }

    // bedrooms
    if (orderedBedrooms.length >= 1) {
      let bedroomsValues = []
      orderedBedrooms.forEach(function (value) {
        let bedLabel = value === 1 ? `${value}` : `${value}`
        bedroomsValues.push({
          label: bedLabel,
          value: value,
          isChecked: true
        })
      })
      filtersList.push({
        id: 2,
        name: "bedrooms",
        displayName: "Bedrooms",
        type: "checkbox",
        values: bedroomsValues
      })
    }

    //floors
    // if (orderedFloors.length > 1) {
    //   let floorValues = []
    //   orderedFloors.forEach(function (value) {
    //     floorValues.push({
    //       label: value,
    //       value: value,
    //       isChecked: false
    //     })
    //   })
    //   filtersList.push({
    //     id: 3,
    //     name: "floor",
    //     displayName: "Floor",
    //     type: "checkbox",
    //     values: floorValues
    //   })
    // }

    //house types
    // if (orderedHouseTypes.length > 1) {
    //   let houseTypesValues = []
    //   orderedHouseTypes.forEach(function (value) {
    //     houseTypesValues.push({
    //       label: value,
    //       value: value,
    //       isChecked: false
    //     })
    //   })

    //   filtersList.push({
    //     id: 4,
    //     name: "houseTypes",
    //     displayName: "Type",
    //     type: "checkbox",
    //     values: houseTypesValues
    //   })
    // }

    // custom fields
    // let customFiltersIDs = [
    //   ...new Set(allCustomFilters.flatMap(({ id }) => id))
    // ].sort()

    // customFiltersIDs.forEach(function (id) {
    //   //get this field
    //   let field = allCustomFilters.filter((cf) => {
    //     if (cf.id === id) {
    //       return cf
    //     } else {
    //       return null
    //     }
    //   })

    //   //get unique values
    //   let uniqueValues = [
    //     ...new Set(field.flatMap(({ fieldValue }) => fieldValue))
    //   ].sort()

    //   //if a checkbox
    //   if (field[0].dataFilterType === 2) {
    //     let values = []
    //     uniqueValues.forEach(function (value) {
    //       values.push({
    //         label: value,
    //         value: value,
    //         isChecked: false
    //       })
    //     })

    //     filtersList.push({
    //       id: id,
    //       name: field[0].displayName,
    //       displayName: field[0].displayName,
    //       type: "checkbox",
    //       values: values
    //     })

    //     //boolean
    //   } else if (field[0].dataFilterType === 3) {
    //     let values = []
    //     uniqueValues.forEach(function (value) {
    //       if (value === "true" || value === "True") {
    //         values.push({
    //           label: "Yes",
    //           value: value,
    //           isChecked: false
    //         })
    //       }
    //     })

    //     filtersList.push({
    //       id: id,
    //       name: field[0].displayName,
    //       displayName: field[0].displayName,
    //       type: "checkbox",
    //       values: values
    //     })
    //   } else {
    //     // nothing
    //   }
    // })

    // setFilters(filtersList)
    appDispatch({
      type: "SET_FILTERS",
      payload: filtersList
    })

    // set available homes
    // setAvailableHomes(homes)
    appDispatch({
      type: "SET_AVAILABLE_HOMES",
      payload: homes
    })

    //set all to filtered items for first render
    // setFilteredItems(homes)
    appDispatch({
      type: "SET_FILTERED_HOMES",
      payload: homes
    })
  }
}

export function runFilter(appState, appDispatch) {
  // console.log("appState.filters", appState.filters)
  // console.log("appState.availableHomes", appState.availableHomes)
  if (appState.availableHomes) {
    var filteredResults = appState.availableHomes.filter((el, index) => {
      let active = false // default to show

      if (appState.filters) {
        appState.filters.forEach(function (filter) {
          if (filter.type === "range") {
            //range
            let itemValue = parseInt(el[filter.name])

            if (
              filter.selectedValues[0] <= itemValue &&
              filter.selectedValues[1] >= itemValue
            ) {
            } else {
              active = false
            }
          } else if (filter.type === "checkbox") {
            //checkbox

            //get the filter values from filter into an array
            let filterValues = []
            filter.values.forEach(function (val) {
              if (val.isChecked) {
                filterValues.push(val.value)
              }
            })

            if (filter.name === "bedrooms") {
              if (filterValues && filterValues.length) {
                if (filterValues.includes(el.plotType.numberOfBeds)) {
                  active = true
                }
              }
            } else if (filter.name === "floor") {
              if (filterValues && filterValues.length) {
                if (filterValues.includes(el.floorData.floorName)) {
                  active = true
                }
              }
            } else if (filter.name === "houseTypes") {
              if (filterValues && filterValues.length) {
                if (filterValues.includes(el.plotType.name)) {
                  active = true
                }
              }
            } else {
              //custom filters

              if (filterValues && filterValues.length) {
                //get this custom field value
                let customFieldValue = null
                if (el.customFields && el.customFields.length) {
                  el.customFields.forEach(function (customField) {
                    if (customField.id === filter.id) {
                      customFieldValue = customField.fieldValue
                    }
                  })
                }

                if (filterValues && filterValues.length) {
                  if (filterValues.includes(customFieldValue)) {
                    // found element
                    active = true
                  }
                }
              }
            }
          }
        })
      }
      return active
    })

    // setFilteredItems(filteredResults)
    appDispatch({
      type: "SET_FILTERED_HOMES",
      payload: filteredResults
    })
  }
}
