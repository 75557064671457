import React, { useContext } from "react"
import { Context } from "../Store"
import { availableCount } from "../utilities"
import "./AvailabilityBadge.scss"

function AvailabilityBadge(props) {
  const [appState] = useContext(Context)
  const { plots } = props

  //get avail colour
  let availableStatus = appState.plotStatuses.find(
    (el) => el.name === "Available"
  )

  //get sold colour
  let soldStatus = appState.plotStatuses.find((el) => el.name === "Sold")

  return (
    <div className="badge-container">
      {plots && availableCount(plots, appState.plotStatuses) ? (
        <span
          className="badge-container__badge"
          style={{
            color: availableStatus.color,
            borderColor: availableStatus.color
          }}
        >
          {availableCount(plots, appState.plotStatuses)} Available
        </span>
      ) : (
        <span
          className="badge-container__badge"
          style={{
            color: soldStatus.color,
            borderColor: soldStatus.color
          }}
        >
          Not Available
        </span>
      )}
    </div>
  )
}

export default AvailabilityBadge
