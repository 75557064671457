import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import PageLoader from "./PageLoader"

function Siteplan(props) {
  const { siteplans, activeSiteplan } = props
  return (
    <div className="siteplan-wrapper">
      {siteplans ? (
        siteplans.map((siteplan) => {
          return (
            <div key={siteplan.name}>
              <img
                src={siteplan.frameUrl}
                alt={`${siteplan.name} Siteplan`}
                key={siteplan.name}
              />

              <AnimatePresence>
                {siteplan.plotsToShow
                  ? siteplan.plotsToShow.map((plotToShow) => {
                      return (
                        <motion.div
                          initial={{
                            opacity: 0,
                            y: -20,
                            position: "absolute"
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            position: "absolute",
                            zIndex: 99
                          }}
                          whileHover={{
                            scale: 2
                          }}
                          whileTap={{
                            scale: 2
                          }}
                          exit={{ opacity: 0, y: -50 }}
                          transition={{
                            duration: 0.25,
                            ease: [0.6, 0.01, -0.05, 0.95]
                          }}
                          style={{
                            left: `${(plotToShow.x / 4000) * 100}%`,
                            top: `${(plotToShow.y / 4000) * 100}%`,
                            transformOrigin: "bottom center",
                            paddingBottom: "5px"
                          }}
                          key={plotToShow.plotNumber}
                        >
                          <div
                            className="pin"
                            data-plot={plotToShow.plotNumber}
                            id={`pin-${plotToShow.plotNumber}`}
                          ></div>
                        </motion.div>
                      )
                    })
                  : null}
              </AnimatePresence>
            </div>
          )
        })
      ) : (
        <PageLoader />
      )}
    </div>
  )
}

export default Siteplan
