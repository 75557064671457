import React from "react"
import { Link } from "react-router-dom"

function NotFound() {
  return (
      <div className="text-center">
        <h2 className="page-title">Whoops, we cannot find that page.</h2>
        <p className="lead text-muted">
          You can always visit the <Link to="/">homepage</Link>
        </p>
      </div>
  )
}

export default NotFound
