import React, { useContext } from "react";

import { Context } from "../Store";
import { priceFormatter } from "../utilities";

import { ReactComponent as LocationIcon } from "../icons/location-icon.svg";
import { ReactComponent as BuildingIcon } from "../icons/building-light-icon.svg";
import { ReactComponent as FloorIcon } from "../icons/layer-group-light-icon.svg";

import "./PlotCard.scss";

function PlotCard(props) {
  const [appState] = useContext(Context);
  const {
    plot,
    showPlotMarker,
    isPlotActive,
    isApartment,
    showBlockPlan,
    isShowBlockPlanActive,
  } = props;

  //get status
  let status = appState.plotStatuses.filter((el) => {
    if (el.id === plot.plotStatusId) {
      return el;
    }
    return null;
  })[0];

  return plot && status ? (
    <div className="plot-card">
      {isApartment ? (
        <button
          onClick={(event) => showBlockPlan()}
          className={
            isShowBlockPlanActive
              ? "plot-card__locate-button plot-card__locate-button--active"
              : "plot-card__locate-button"
          }
        >
          <LocationIcon />
        </button>
      ) : plot.onSitePlan ? (
        <button
          onClick={(event) =>
            showPlotMarker(plot.plotNumber, plot.phase, event)
          }
          className={
            isPlotActive(plot.plotNumber, plot.phase)
              ? "plot-card__locate-button plot-card__locate-button--active"
              : "plot-card__locate-button"
          }
        >
          <LocationIcon />
        </button>
      ) : null}

      <div className="plot-card__title">
        <h3>Plot {plot.plotNumber} </h3>
        <span
          className="plot-card__title__status"
          style={{ color: status.color }}
        >
          {status.name}
        </span>
      </div>

      {plot.floorData?.blockName && (
        <p className="plot-card__meta">
          <BuildingIcon className="plot-card__meta__icon" />
          {plot.floorData.blockName}
        </p>
      )}
      {plot.floorData?.floorName && (
        <p className="plot-card__meta">
          <FloorIcon className="plot-card__meta__icon" />
          {plot.floorData.floorName}
        </p>
      )}

      <p className="plot-card__price">
        {plot.price ? priceFormatter(plot.price) : null}
      </p>
    </div>
  ) : null;
}

export default PlotCard;
