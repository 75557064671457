import React, { useContext } from "react"
import { Context } from "../../Store"
import PageLoader from "../../components/PageLoader"

import PageTransition from "../../components/PageTransition"
import TooltipButton from "../../components/TooltipButton"

import SwiperWrapper from "../../components/SwiperWrapper"
import { SwiperSlide } from "swiper/react"

const Dims = (props) => {
  const { dims } = props

  return (
    <table className="dims">
      <tbody>
      {dims.map((item) => {
        return (
          <tr className="dims__room" key={item.id}>
            <td className="dims__room__item">
              <strong>{item.name}</strong>
            </td>
            <td className="dims__room__item">{item.imperialDimensions}</td>
            <td className="dims__room__item">{item.metricDimensions}</td>
          </tr>
        )
      })}
      </tbody>
    </table>
  )
}

function Floorplans() {
  const [appState] = useContext(Context)

  return (
    <PageTransition>
      {appState.currentTypeData ? (
        <SwiperWrapper>
          {appState.currentTypeData && appState.currentTypeData.floorplans ? (
            appState.currentTypeData.floorplans.map((item) => {
              return (
                <SwiperSlide key={item.assetId}>
                  <div className="swiper__slide">
                    <div className="swiper__slide__image ">
                      <img src={item.fileUrl} alt={item.caption} />
                      <p className="swiper__slide__caption">{item.caption}</p>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })
          ) : (
            <PageLoader />
          )}
        </SwiperWrapper>
      ) : (
        <PageLoader />
      )}

      {appState.currentTypeData ? (
        <TooltipButton title="Dimensions" icon="ruler">
          <Dims dims={appState.currentTypeData.dimensions} />
        </TooltipButton>
      ) : null}
    </PageTransition>
  )
}

export default Floorplans
