import React, { useContext } from "react"
import { Context } from "../../Store"
import PageLoader from "../../components/PageLoader"

import PageTransition from "../../components/PageTransition"

import parse from "html-react-parser"
import TooltipButton from "../../components/TooltipButton"

import SwiperWrapper from "../../components/SwiperWrapper"
import { SwiperSlide } from "swiper/react"

function Overview() {
  const [appState, appDispatch] = useContext(Context)

  return (
    <PageTransition>
      {appState.currentTypeData ? (
        <SwiperWrapper>
          {appState.currentTypeData && appState.currentTypeData.sortedImages ? (
            appState.currentTypeData.sortedImages.map((image) => {
              return !image.name.includes("FP") ? (
                //not floorplans
                <SwiperSlide key={image.assetId}>
                  <div className="swiper__slide">
                    <div className="swiper__slide__image">
                      <img src={image.fileUrl} alt={image.name} />
                    </div>
                  </div>
                </SwiperSlide>
              ) : null
            })
          ) : (
            <PageLoader />
          )}
        </SwiperWrapper>
      ) : (
        <PageLoader />
      )}

      {appState.currentTypeData ? (
        <TooltipButton title="Description" icon="info">
          {parse(`${appState.currentTypeData.description}`)}
        </TooltipButton>
      ) : null}
    </PageTransition>
  )
}

export default Overview
