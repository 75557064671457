import React, { useRef } from "react"
import { useNavigate } from "react-router"
import IdleTimer from 'react-idle-timer'

function IdleTimerContainer() {
  
  const idleTimerRef = useRef(null)
  const navigate = useNavigate()

  const handleAction = ()=> idleTimerRef.current.start()

  const timeout = () => {
    navigate('/')
  }

  return (
    <div>
      <IdleTimer ref={idleTimerRef}  startOnMount={false} onAction={handleAction} stopOnIdle={true} timeout={5 * 60000} onIdle={timeout}></IdleTimer>
    </div>
  )
}

export default IdleTimerContainer