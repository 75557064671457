import React, { useContext, useEffect } from "react"
import { Routes, Route, useParams, Outlet, Navigate } from "react-router-dom"
import Page from "../Page"
import { Context } from "../../Store"
import Loader from "../../components/Loader"

import Overview from "./Overview"
import Floorplans from "./Floorplans"
import Homes from "./Homes"

import Footer from "../../components/Footer"

function SingleType() {
  const [appState, appDispatch] = useContext(Context)

  const currentTypeID = parseInt(useParams().id)

  //set current type info
  useEffect(() => {
    if (appState.typesData.length > 0) {
      appState.typesData.filter((el) => {
        if (el.id === currentTypeID) {
          // setCurrentTypeData(el)
          appDispatch({
            type: "SET_CURRENT_TYPE",
            payload: el
          })
        }
        return null
      })
    }
  }, [appState.typesData, currentTypeID])

  return (
    <>
      {appState.currentTypeData ? (
        <Page
          title={`${
            appState.currentTypeData ? appState.currentTypeData.name : null
          }`}
        >
          <div className="main">
            <div className="content">
              <Routes>
                <Route index element={<Navigate replace to="overview" />} />
                <Route path="overview" element={<Overview />} />
                <Route path="floorplans" element={<Floorplans />} />
                <Route path="homes" element={<Homes />} />
              </Routes>

              <Outlet />
            </div>
          </div>
          <Footer type="housetype" />
        </Page>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default SingleType
