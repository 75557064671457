import { useEffect, useContext } from "react"
import { Context } from "./Store"
import axios from "axios"

function useThinkHubData() {
  const [appState, appDispatch] = useContext(Context)

  const BASE_URL = "https://www.think-hub.co.uk/api/v1"

  const endPoints = [
    {
      name: "devName",
      url: `${BASE_URL}/GetStartupInformation/${appState.devId}`
    },
    { name: "devStyle", url: `${BASE_URL}/GetStyle/${appState.devId}/true` },
    {
      name: "plotStatuses",
      url: `${BASE_URL}/GetPlotStatuses/${appState.devId}/true`
    },
    { name: "plotsData", url: `${BASE_URL}/GetPlots/${appState.devId}/true` },
    { name: "plotContainers", url: `${BASE_URL}/GetPlotsContainer/${appState.devId}/true` },
    { name: "menuItems", url: `${BASE_URL}/GetMenuItems/${appState.devId}/` }
  ]

  function getAllData(endPoints) {
    Promise.all(endPoints.map((endPoint) => getData(endPoint))).then(function (
      results
    ) {
      return results
    })
  }

  function getData(endPoint) {
    return axios
      .get(endPoint.url, {
        headers: { "Registration-Key": appState.devKey }
      })
      .then((response) => {
        appDispatch({
          type: `SET_API_DATA`,
          key: endPoint.name,
          payload: response.data
        })
        appDispatch({ type: `SET_API_LOADED`, key: endPoint.name })
        return response
      })
      .catch((error) => {
        appDispatch({ type: `SET_API_ERROR`, key: endPoint.name })
      })
  }

  useEffect(() => {
    if (appState.devId) {
      getAllData(endPoints)
    }
  }, [appState.devId])

  //check all is loaded
  useEffect(() => {
    if (appState.loaded.length === endPoints.length) {
      appDispatch({ type: `SET_API_ALL_LOADED` })
    }
  }, [appState.loaded])

  return true
}

export default useThinkHubData
