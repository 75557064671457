import React from "react"
import { motion } from "framer-motion"

function PageTransition(props) {
  const { children } = props
  const pageVariants = {
    hidden: { opacity: 0, y: 100 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.6
      }
    },
    exit: {
      opacity: 0,
      y: -100,
      transition: {
        ease: "easeInOut",
        duration: 0.4
      }
    }
  }

  return (
    <motion.div
      className="content-container"
      initial="hidden"
      animate="show"
      exit="exit"
      variants={pageVariants}
    >
      {children}
    </motion.div>
  )
}

export default PageTransition
