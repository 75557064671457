import React, { useContext } from "react"
import { Context } from "../Store"
import "./Footer.scss"
import SubMenu from "../components/SubMenu"
import FilterMenu from "../components/FilterMenu"
import { Link } from "react-router-dom"
import { priceFormatter } from "../utilities"
import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PricesIcon } from "../icons/prices-icon.svg"
import { ReactComponent as RightArrowIcon } from "../icons/long-arrow-right-light.svg"
import AvailabilityBadge from "../components/AvailabilityBadge"

function FooterBottom() {
  const [appState] = useContext(Context)
  const { devName } = appState

  const logo = appState.devStyle.images.additionalLogoUrl

  return (
    <div className="bottom-footer">
      <div className="bottom-footer__text">
        <h4 className="bottom-footer__text__site-name">
          <Link to="/">{devName && devName.name ? devName.name : null}</Link>
        </h4>
        <p>
          <Link to="/">
            <img
              src={logo}
              alt=""
              className="bottom-footer__text__developer-logo"
              width="150"
            />
          </Link>
        </p>
      </div>

      {/* <div className="bottom-footer__pattern"></div> */}
    </div>
  )
}

function HousetypesFooter() {
  const [appState] = useContext(Context)
  const { currentTypeData } = appState
  const { name, fromPrice, numberOfBeds } = currentTypeData

  return (
    <div className="footer">
      <div className="footer__info">
        <div className="footer__info__item">
          <div className="svg-icon-inline baseline">
            <PricesIcon />
          </div>
          From {fromPrice ? priceFormatter(fromPrice) : null}
        </div>
        <div className="footer__info__item">
          <div className="svg-icon-inline baseline">
            <BedsIcon />
          </div>
          Bedrooms {numberOfBeds ? numberOfBeds : null}
        </div>
        <div className="footer__info__item">
          <AvailabilityBadge plots={currentTypeData.plots} />
        </div>
      </div>
      <div className="footer__menu">
        <h1 className="footer__menu__title">
          <Link to="/types" className="footer__menu__link">
            Housetypes
          </Link>{" "}
          <div className="footer__menu__arrow svg-icon-inline baseline">
            <RightArrowIcon />
          </div>{" "}
          {name ? name : null}
        </h1>
        <SubMenu />
      </div>
    </div>
  )
}

function TypesFooter() {
  return (
    <div className="footer">
      <div className="footer__info"></div>
      <div className="footer__menu">
        <h1 className="footer__menu__title">Housetypes</h1>
        <FilterMenu />
      </div>
    </div>
  )
}

function Footer(props) {
  const { type } = props
  return (
    <footer>
      <div className="footer-container">
        {type === "housetype" ? <HousetypesFooter /> : null}
        {type === "types" ? <TypesFooter /> : null}
      </div>
      <FooterBottom />
    </footer>
  )
}

export default Footer
