import React, { useContext } from "react"

import { Context } from "../Store"

import { ReactComponent as CloseIcon } from "../icons/close-icon.svg"

import "./PlotBlockPlan.scss"

function PlotBlockPlan(props) {
  const [appState] = useContext(Context)
  const { plot, close } = props

  console.log(plot)

  return (
    <div className="plot-block-plan">
      <div className="plot-block-plan-inner">
        <h2 className="plot-block-plan-title"><span className="plot-block-plan-title--primary">{plot.floorData.blockName}</span> {plot.floorData.floorName}</h2>
        <div className="plot-block-plan-image">
          <img src={plot.floorData.imgURL} alt={plot.floorData.blockName}/>
          <div
            className="plot-block-plan-marker"
            style={{
              left: `${plot.position.x * 100}%`,
              top: `${plot.position.y * 100}%`,
              width: `${plot.position.width * 120}%`,
              height: `${plot.position.height * 120}%`
            }}
          >
            {plot.plotNumber}
          </div>
        </div>
        <button className="plot-block-plan-close" onClick={close}><CloseIcon /></button>
      </div>
    </div>
  )
}

export default PlotBlockPlan
