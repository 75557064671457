import { useEffect, useContext } from "react"
import { Context } from "./Store"
import axios from "axios"

function useSiteplanData() {
  const [appState, appDispatch] = useContext(Context)

  let endPoints = []
  let siteplanData = []

  if (appState.siteplans) {
    for (const siteplan of appState.siteplans) {

      let frameNumber =  appState.siteplanFrames ? appState.siteplanFrames[siteplan.slug] : null
      let siteplanRouteUrl = siteplan.url
      let siteplanDataUrl = `${siteplan.url}/spinExport.json`
      let siteplanFrameUrl = `${siteplanRouteUrl}/4k/spin_${frameNumber}.jpg`

      endPoints.push({ name: siteplan.title, dataUrl: siteplanDataUrl, frameUrl: siteplanFrameUrl, slug: siteplan.slug, frameNumber: frameNumber})
    }
  }

  function getAllData(endPoints) {
    Promise.all(endPoints.map((endPoint) => getData(endPoint))).then(function (
      results
    ) {
      appDispatch({ type: `SET_SITEPLAN_DATA`, payload: results })
      return results
    })
  }

  function getData(endPoint) {
    return axios.get(endPoint.dataUrl)
    .then(response => {

      let frameNumber = Number(endPoint.frameNumber)
      let frameName = `FRAME_${frameNumber}`
      let frameData = response.data[frameName]
      siteplanData = {name: endPoint.name, frameData: frameData, frameUrl: endPoint.frameUrl}

      return siteplanData

    }).catch(error => {
      appDispatch({ type: `SET_API_ERROR`, key: endPoint.name })
    })
  }

  useEffect(() => {
    if (appState.devId && appState.siteplans) {
        getAllData(endPoints)
    }
  }, [appState.devId, appState.siteplans, appState.siteplanFrames])


  return true 
}

export default useSiteplanData
