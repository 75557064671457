import React from "react"
import Loader from "./Loader"

function PageLoader() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "0"
      }}
    >
      <Loader />
    </div>
  )
}

export default PageLoader
