import React from "react"
import { Swiper } from "swiper/react"
import SwiperCore, { Scrollbar } from "swiper"
SwiperCore.use([Scrollbar])

function SwiperWrapper(props) {
  const { children } = props

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1.2}
      centeredSlides={false}
      centerInsufficientSlides={true}
      observer
      observeParents
      preloadImages={true}
      breakpoints={{
        "@0.75": {
          slidesPerView: 2
        },
        "@1.00": {
          slidesPerView: 3
        },
        "@1.50": {
          slidesPerView: 2.7
        }
      }}
      scrollbar={{ draggable: true, el: ".scrollbar-menu__scrollbar" }}
      preventClicks={false}
      preventClicksPropagation={false}
    >
      <div className="scrollbar-menu">
        <div className="scrollbar-menu__scrollbar" />
      </div>
      {children}
    </Swiper>
  )
}

export default SwiperWrapper
