import React from "react"
import { Routes, Route } from "react-router-dom"

//Pages
import Home from "./Home"
import Types from "./Types"
import NotFound from "./NotFound"
import SingleType from "./SingleType/SingleType"
import AppSettings from "./AppSettings"

import { AnimatePresence } from "framer-motion"

function AppRoutes() {
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="app-settings" element={<AppSettings />} />
        <Route path="types" element={<Types />} />
        <Route path="types/:id/*" element={<SingleType />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  )
}

export default AppRoutes
