import React, { createContext, useReducer } from "react"
import Reducer from "./Reducer"

export const initialState = {
  devId: (localStorage.getItem("devId")) ? localStorage.getItem("devId") : null,
  devKey: (localStorage.getItem("devKey")) ? localStorage.getItem("devKey") : null,
  siteplanFrames: (localStorage.getItem("siteplanFrames")) ? JSON.parse(localStorage.getItem("siteplanFrames")) : null,

  // from thinkhub
  devName: null,
  devStyle: "",
  plotStatuses: "",
  plotsData: [],
  menuItems: [],

  typesData: [],
  currentTypeData: [],

  siteplans: [],
  siteplanImage: "",

  //from assets
  siteplanData: [],

  loaded: [],
  error: [],
  allLoaded: false,
  imagesPreloaded: false,

  filters: [],
  availableHomes: [],
  filteredHomes: []
  // rangeLabels: [],
}

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  )
}

export const Context = createContext(initialState)
export default Store
