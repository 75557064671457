import React, { useState, useEffect, useContext } from "react"
import { Context } from "../Store"

function AppSettings() {
  const [appState, appDispatch] = useContext(Context)

  //development data
  const [devId, setDevId] = useState("")
  const [devKey, setDevKey] = useState("")

  //update from appState
  useEffect(() => {
    if (appState.devId && appState.devId) {
      setDevId(appState.devId)
      setDevKey(appState.devKey)
    }
  }, [])

  function handleDevSubmit(e) {
    e.preventDefault()
    localStorage.removeItem("siteplanFrames")
    appDispatch({
      type: "RESET",
    })
    appDispatch({
      type: "SET_DEV_DATA",
      payload: { devId: devId, devKey: devKey }
    })
  }

  //siteplans
  const [siteplanFrames, setSiteplanFrames] = useState([])

  //update from appState
  useEffect(() => {
    if (appState.siteplanFrames) {
      setSiteplanFrames(appState.siteplanFrames)
    }
  }, [])

  function setSiteplanFrame(e) {
    e.preventDefault()
    setSiteplanFrames({ ...siteplanFrames, [e.target.name]: e.target.value })
  }

  function handleSiteplanSubmit(e) {
    e.preventDefault()

    appDispatch({
      type: "SET_SITEPLAN_FRAMES",
      payload: siteplanFrames
    })
  }

  return (
    <div className="app-settings">
      <form onSubmit={handleDevSubmit} className="app-settings__form">
        <div className="app-settings__form-item">
          <label className="app-settings__label" htmlFor="dev-id">
            Development ID:
          </label>
          <input
            type="text"
            name="dev-id"
            id="dev-id"
            onChange={(e) => setDevId(e.target.value)}
            autoComplete="off"
            value={devId}
          />
        </div>

        <div className="app-settings__form-item">
          <label className="app-settings__label" htmlFor="dev-key">
            Development Key:
          </label>
          <input
            type="text"
            name="dev-key"
            id="dev-key"
            onChange={(e) => setDevKey(e.target.value)}
            autoComplete="off"
            value={devKey}
          />
        </div>

        <button className="app-settings__button">Save Development</button>
      </form>

      {appState.siteplans.length > 0 && (
        <form
          onSubmit={handleSiteplanSubmit}
          className="siteplan-settings__form"
        >
          {appState.siteplans.length > 0
            ? appState.siteplans.map((siteplan, key) => {
                return (
                  <div key={key}>
                    <div className="siteplan-settings__form-item">
                      <label
                        className="siteplan-settings__label"
                        htmlFor="siteplan-frame"
                      >
                        <strong>{siteplan.title}</strong> Frame ID:
                      </label>
                      <input
                        type="text"
                        name={siteplan.slug}
                        id="siteplan-frame"
                        onChange={setSiteplanFrame}
                        autoComplete="off"
                        value={siteplanFrames[siteplan.slug]}
                      />
                    </div>
                  </div>
                )
              })
            : null}
          <button className="siteplan-settings__button">Save Settings</button>
        </form>
      )}
    </div>
  )
}

export default AppSettings
