import { initialState } from "./Store"


const Reducer = (state, action) => {
  switch (action.type) {
    case "RESET":
        return initialState
    case "SET_DEV_DATA":
      return {
        ...state,
        devId: action.payload.devId,
        devKey: action.payload.devKey
      }
    case "SET_SITEPLAN_FRAMES":
      return {
        ...state,
        siteplanFrames: action.payload,
      }
    case "SET_API_DATA":
      return {
        ...state,
        [action.key]: action.payload
      }
    case "SET_API_LOADED":
      return {
        ...state,
        loaded: [...state.loaded, action.key]
      }
    case "SET_API_ERROR":
      return {
        ...state,
        error: {
          ...state.error,
          [action.key]: true
        }
      }
    case "SET_API_ALL_LOADED":
      return {
        ...state,
        allLoaded: true
      }
    case "SET_TYPES_DATA":
      return {
        ...state,
        typesData: action.payload
      }

    case "SET_PLOTS_DATA":
      return {
        ...state,
        plotsData: action.payload
      }

    //filters
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload
      }
    case "SET_AVAILABLE_HOMES":
      return {
        ...state,
        availableHomes: action.payload
      }
    case "SET_FILTERED_HOMES":
      return {
        ...state,
        filteredHomes: action.payload
      }
    case "SET_IMAGES_PRELOADED":
      return {
        ...state,
        imagesPreloaded: true
      }

    case "SET_CURRENT_TYPE":
      return {
        ...state,
        currentTypeData: action.payload
      }

    case "SET_SITEPLANS":
      return {
        ...state,
        siteplans: action.payload
      }

    case "SET_SITEPLAN_DATA":
      return {
        ...state,
        siteplanData: action.payload
      }

    default:
      return state
  }
}

export default Reducer
