import React, { useContext } from "react"
import { Link } from "react-router-dom"

import { Context } from "../Store"

import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PricesIcon } from "../icons/prices-icon.svg"
import { ReactComponent as RightArrowIcon } from "../icons/long-arrow-right-light.svg"
import AvailabilityBadge from "../components/AvailabilityBadge"

//utilities
import { priceFormatter, availableCount } from "../utilities"

import "./TypeCard.scss"

function TypeCard(props) {
  const [appState, appDispatch] = useContext(Context)

  const { item } = props

  let img = item.sortedImages[0]

  return (
    <div className="swiper__slide">
      <div className="swiper__slide__image">
        <Link to={`/types/${item.id}`}>
          <img src={img.fileUrl} alt={item.name} />
        </Link>
      </div>
      <div className="swiper__slide__content">
        <div className="type-details">
          <div className="type-details__title">
            <h2>{item.name}</h2>
            <AvailabilityBadge plots={item.plots} />
          </div>

          <div className="type-details__secondary">
            <div className="type-details__items">
              <div className="type-details__items__item">
                <div className="svg-icon-inline baseline no-padding">
                  <BedsIcon />
                </div>{" "}
                {item.numberOfBeds} bedroom home
              </div>
              {availableCount(item.plots, appState.plotStatuses) >= 1 ? (
                <div className="type-details__items__item">
                  <div className="svg-icon-inline baseline no-padding">
                    <PricesIcon />
                  </div>{" "}
                  Priced from {priceFormatter(item.fromPrice)}
                </div>
              ) : null}
            </div>

            <Link to={`/types/${item.id}`} className="type-details__link">
              View Housetype
              <div className="svg-icon-inline baseline">
                <RightArrowIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeCard
