import React from "react"
import { NavLink } from "react-router-dom"
import "./SubMenu.scss"

function SubMenu() {
  
  let itemClass = (isActive) => {
    return isActive
      ? "sub-menu__button sub-menu__button--active"
      : "sub-menu__button"
  }

  return (
    <div className="sub-menu">
      <NavLink to="overview" className={({ isActive }) => itemClass(isActive)} >
        Overview
      </NavLink>
      <NavLink
        to="floorplans"
        className={({ isActive }) => itemClass(isActive)}
      >
        Floorplans
      </NavLink>
      <NavLink
        to="homes"
        className={({ isActive }) => itemClass(isActive)}
      >
        Homes
      </NavLink>
    </div>
  )
}

export default SubMenu
