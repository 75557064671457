import React, { useState, useEffect, useRef } from "react"

import { ReactComponent as InfoIcon } from "../icons/info-circle-light.svg"
import { ReactComponent as RulerIcon } from "../icons/ruler-combined-light.svg"

import { motion, AnimatePresence } from "framer-motion"

import "./TooltipButton.scss"

const Icon = (props) => {
  const { type } = props
  if (type === "info") {
    return <InfoIcon />
  } else if (type === "ruler") {
    return <RulerIcon />
  } else {
    return <InfoIcon />
  }
}

function TooltipButton(props) {
  const { children, title, icon } = props
  const [isOpen, setIsOpen] = useState(false)

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (isOpen) {
            setIsOpen(false)
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, isOpen])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className="tooltip-button" ref={wrapperRef}>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            initial={{
              opacity: 0,
              y: -20
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              duration: 0.25,
              ease: [0.6, 0.01, -0.05, 0.95]
            }}
          >
            <div className="tooltip-button__content">
              <div className="tooltip-button__inner">{children}</div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <button
        className={`${
          isOpen
            ? "tooltip-button__button tooltip-button__button--active"
            : "tooltip-button__button"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon type={icon} />
      </button>
      <p className="tooltip-button__title">{title}</p>
    </div>
  )
}

export default TooltipButton
