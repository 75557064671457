import React, {
  useContext,
} from "react"
import { Context } from "../Store"

import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"

import "./FilterMenu.scss"

function FilterMenu(props) {
  const [appState, appDispatch] = useContext(Context)

  //checkbox
  const handleCheckboxChange = (filterIndex, valueIndex) => (e) => {
    let newFilters = JSON.parse(JSON.stringify(appState.filters)) // copying the old

    newFilters[filterIndex].values[valueIndex].isChecked =
      !newFilters[filterIndex].values[valueIndex].isChecked

    //add active state to filter
    if (newFilters[filterIndex].values.some((e) => e.isChecked)) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }

    // setFilters(newFilters)
    appDispatch({
      type: "SET_FILTERS",
      payload: newFilters
    })
  }

  return (
    <>
      {appState.filters && appState.filters.length ? (
        <div className="filters">
          {appState.filters
            ? appState.filters.map((item, filterIndex) => {
                if (item.type === "checkbox") {
                  return (
                    <div className="filters__filter" key={filterIndex}>
                      <div className="filters__filter__item">
                        {item.values.map((item, valueIndex) => {
                          return (
                            <button
                              key={valueIndex}
                              checked={item.isChecked}
                              onClick={handleCheckboxChange(
                                filterIndex,
                                valueIndex
                              )}
                              className={`filters__filter__item__button ${
                                item.isChecked
                                  ? "filters__filter__item__button--active"
                                  : ""
                              }`}
                              id={`check-${filterIndex}-${valueIndex}`}
                            >
                              <div className="svg-icon-inline baseline">
                                <BedsIcon />
                              </div>
                              x{item.label}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })
            : null}
        </div>
      ) : null}{" "}
    </>
  )
}

export default FilterMenu
