import React, { useContext } from "react"
import { Context } from "../Store"
import Page from "./Page"
import TypeCard from "../components/TypeCard"
import Footer from "../components/Footer"
import PageTransition from "../components/PageTransition"
import PageLoader from "../components/PageLoader"

import SwiperWrapper from "../components/SwiperWrapper"
import { SwiperSlide } from "swiper/react"

function Types() {
  const [appState, appDispatch] = useContext(Context)

  function plotFilteredCheck(plotID) {
    let plotInFiltered = appState.filteredHomes.find((el) => el.id === plotID)
    return plotInFiltered ? true : false
  }

  function filteredCount(array) {
    var count = 0
    array.map((i) => {
      if (plotFilteredCheck(i.id)) {
        count++
      }
      return null
    })
    return count
  }

  return (
    <Page title="Housetypes">
      <div className="main">
        <div className="content">
          <PageTransition>
            <SwiperWrapper>
              {appState.typesData.length >= 1 ? (
                appState.typesData.map((item) => {
                  if (filteredCount(item.plots)) {
                    return (
                      <SwiperSlide key={item.id}>
                        <TypeCard item={item} />
                      </SwiperSlide>
                    )
                  } else {
                    return null
                  }
                })
              ) : (
                <PageLoader />
              )}
            </SwiperWrapper>
            {!appState.filteredHomes.length &&
              <h2 className="no-results">No homes matched your criteria. Please try different options.</h2>
            }
          </PageTransition>
        </div>
      </div>

      <Footer type="types" />
    </Page>
  )
}

export default Types
