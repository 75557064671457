import React, { useContext, useEffect } from "react"

import { Context } from "../Store"

function Page(props) {
  const [appState, appDispatch] = useContext(Context)

  useEffect(() => {
    if (appState.devName && appState.devName.name) {
      document.title = `${props.title} | ${appState.devName.name}`
      window.scrollTo(0, 0)
    }
  }, [props.title, appState.devName])

  return (
    <div className="app">
      {props.children}
    </div>
  )
}

export default Page
